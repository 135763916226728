import React, { Component } from "react";
import $ from "jquery";
import grocery from "../assets/images/grocery_graphic.png";
import checkbox from "../assets/images/checkbox-icon.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import DatePicker from "react-datepicker";
import save from "../assets/images/save_icon.png";
import print from "../assets/images/print-icon.png"; 
import "react-datepicker/dist/react-datepicker.css";

class NewSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
        date: null,
        account_ledger:null,
        amount:null,
        payment_method:null,
        loading:false,
        amount_error:null,
        account_error:null,
    };
    // window.location.reload(true)
    this.onChange = this.onChange.bind(this);
    this.receiveCash = this.receiveCash.bind(this);
    
  }

  shouldComponentUpdate(){
    
  }

  componentDidMount() {}

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
  onChange(e){
    this.setState({[e.target.name]:e.target.value});
}

  onChanging(date) {
    this.setState({ date });
  }
  handleChange = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({date:formatedDate})
  }

  validation() {
    if (this.state.amount == "") {
          this.setState({
            amount_error: "* Please enter amount",
          });
        } else if(this.state.account_ledger == ""){
          this.setState({
            account_error: "* Please select ledger",
          });
        }else{
          return true;
    }
  }
  receiveCash() {

        if (this.validation() == true) {
          this.setState({ loading: true });
          
        }
  
  }

  render() {
    // window.location.reload(true)
    const loading=this.state.loading
    const date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    const setDate = (this.state.date !==null) ? this.state.date : formatedDate;
    const ndate = setDate; 
    if (this.state.redirectToReferrer) {
      return <Redirect to='/login' />;
    }
     
    return (
      <div>
        <Header />

        <div className="dashboard_body main_body_style auto_scrol" id="sale_order_page">
           <div className="pos_main">
                <div className="pos_black_box" id="new_selection">
                 <div className="main_body">
                    <div className="login_screen" id="uder_eng_language">
                      <div className="user_login Select_language process_complete">
                        <div className="form_box_delivery">
                          <h3>Cash Receive </h3>
                          <div className="row cashreceive">
                            <div className="col-sm-12 delivery_address">
                              <label style={{ zIndex: 1 }}>Date</label>
                              <DatePicker
                               value = {ndate}
                               name = "date"
                                onChange={(e)=>this.handleChange(e)}
                              />
                            </div>
                            <div
                              className="col-sm-12 delivery_address"
                              style={{ display: "block" }}
                            >
                              <label>Cash/Bank</label>
                              <select id="customer_id" onChange={this.onChange} name="payment_method">
                                <option value="1">Cash</option>
                                <option value="23">Bank</option>
                              </select>
                            </div>
                            <div
                              className="col-sm-12 delivery_address"
                              style={{ display: "block" }}
                            >
                              <label>Account Ledger</label>
                              <select id="customer_id" onChange={this.onChange} name="account_ledger">
                                <option value="1">Ledger 1</option>
                                <option value="23">Ledger 2</option>
                              </select>
                                <h6 className="voucher_error_message">{this.state.account_error}</h6>
                            </div>  
                            <div
                              className="col-sm-12 delivery_address"
                              style={{ display: "block" }}
                            >
                              <label>Amount</label>
                              <input type="text" onChange={this.onChange} name="amount" placeholder="Enter amount" />
                              <h6 className="voucher_error_message">{this.state.amount_error}</h6>
                            </div>
                            
                          </div>
                          
                            
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
           </div>
        </div>

        <div id="invoice_listing_ftr"  className="total_btm-bar" style={{ padding: "0px" }}>
          <div className="total-amount">

          <button
            className="request_door_click"
            style={{ fontSize: "18px"}}
            type="button"
            onClick={this.receiveCash}
            disabled={loading}
          >
            {loading && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span><img  src={save} /> Save</span>}
          </button>

            <button
            className="save-print request_door_click"
            style={{ fontSize: "18px"}}
            type="button"
            onClick={this.receiveCash}
            disabled={loading}
          >
            {loading && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            )}
            {loading && <span>Loading...</span>}
            {!loading && <span> <img  src={print} /> Save &amp; Print</span>}
          </button>

          {
              /*<button onClick={this.receiveCash} class="request_door_click" type="button"><span><img  src={save} /> Save</span></button>


              <button onClick={this.receiveCash} class="save-print request_door_click" type="button"><span> <img  src={print} /> Save &amp; Print</span></button>*/

          }
          </div>
        </div>
      </div>
    );
  }
}
export default NewSelection;
